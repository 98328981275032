<template>
  <div>
    <vx-card title="BANK Temporary">
      <vs-table stripe :data="banktemporary">

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>For</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Number</vs-th>
          <vs-th>Type</vs-th>
          <vs-th>รายละเอียด</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.bank_id">
              {{tr.bank_id}}
            </vs-td>
            <vs-td :data="tr.bank_for">
              {{tr.bank_for}}
            </vs-td>
            <vs-td :data="tr.bank_fullname">
              {{tr.bank_fullname}}
            </vs-td>
            <vs-td :data="tr.bank_number">
              {{tr.bank_number}}
            </vs-td>
            <vs-td :data="tr.bank_usage_type">
              {{getBankTypeText(tr.bank_usage_type)}}
            </vs-td>
            <vs-td :data="tr.bank_descriptions">
              {{tr.bank_descriptions}}
            </vs-td>
            <vs-td >
              <div class="flex">
                <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" type="border" size="small" icon-pack="feather" icon="icon-edit" color="danger" class="mr-2"

                @click="popupDeleteBankData(tr.bank_id, tr.bank_number, tr.bank_fullname)">ลบบัญชี
              </vs-button>
              </div>
              <div class="flex mt-4">
                <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" type="border" size="small" icon-pack="feather" icon="icon-edit" color="warning" class="mr-2"

                @click="$router.push('/SettingTemporary/EditTemporary/'+temporary+'/'+tr.bank_id)">แก้ไขบัญชีพัก
              </vs-button>
              </div>
            </vs-td>

          </vs-tr>
        </template>

      </vs-table>
      <vs-popup classContent="popup-example" title="ลบบัญชี" :active.sync="showPopupDelete">
      <h4> คุณกำลังจะลบเลขบัญชี: <b style="color:#F61340">{{delete_bankno}} {{ delete_fullname }}</b></h4>
      <br>
      <div class="p-2 px-6 ">
              <vs-input
                v-validate="'required'"
                v-model.trim="otp"
                type ="text"
                placeholder="กรุณากรอกรหัส 2-Factor"
                class="mx-auto"/>
              <div class="vx-row mt-4">
                <vs-button class="vx-col md:w-1/3 mx-auto"
                :disabled="!otp"
                @click="deletedata()" type="filled" size="small"
                color="success">ยืนยันOTP
                </vs-button>
              </div>
            </div>
    </vs-popup>
    </vx-card>
  </div>
</template>

<script>
import axios from '../../axios'

export default {
  data () {
    return {
      banktemporary: [],
      bank: 'bank',
      temporary:'bank_temporary',
      bankid:'',
      delete_id: '',
      delete_bankno:'',
      delete_fullname: '',
      otp: null,
      admin_user: this.$store.state.AppActiveUser,
      admin_pass:'',
      showPopupDelete: false
    }
  },
  async mounted () {
    await axios
      .get('bank/bank/temporary')
      .then(response => (this.banktemporary = response.data))
  },
  methods: {
    getBankTypeText (bankType) {
      switch (bankType) {
      case 'TEMP': return 'บัญชีพัก'
      case 'ATM': return 'บัญชีกดเงิน'
      case 'BILL': return 'บัญชีเบิก'
      case 'PAY': return 'บัญชี(Payment)'
      case 'OTH': return 'บัญชีอื่นๆ(other)'
      }
    },
    async update_bankshow (bank_id, bank_fullname, bank_show) {
      if (bank_show === 1 || bank_show === true) {
        bank_show = 0
      } else {
        bank_show = 1
      }
      await axios
        .post('bank/updatebankshow', {
          bank: 'temporary',
          id: bank_id,
          show: bank_show
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_show === 1 ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_show === 1 ? 'check_box' : 'error',
          title: bank_show === 1 ? 'เปิดแสดง scb หน้าเว็บสำเร็จ' : 'ปิดแสดง scb หน้าเว็บสำเร็จ',
          text: bank_show === 1 ? `บัญชี : ${bank_fullname}` : `บัญชี : ${bank_fullname}`
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: bank_show === 1 ? 'เปิดแสดง scb หน้าเว็บไม่สำเร็จ!!' : 'ปิดแสดง scb หน้าเว็บไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async update_bankrun (bank_id, bank_fullname, bank_run) {
      if (bank_run === 1 || bank_run === true) {
        bank_run = 0
      } else {
        bank_run = 1
      }
      await axios
        .post('bank/bank_all/run', {
          bankid:bank_id,
          bankrun:bank_run,
          bank:'scb'
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_run === 1 ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_run === 1 ? 'check_box' : 'error',
          title: bank_run === 1 ? 'เปิดแสดง SCB หน้าเว็บสำเร็จ' : 'ปิดแสดง SCB หน้าเว็บสำเร็จ',
          text: bank_run === 1 ? `บัญชี : ${bank_fullname}` : `บัญชี : ${bank_fullname}`
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: bank_run === 1 ? 'เปิดดึงยอด SCB หน้าเว็บไม่สำเร็จ!!' : 'ปิดดึงยอด SCB หน้าเว็บไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async update_bankrun_app (bank_id, bank_fullname, bank_run_app) {
      if (bank_run_app === 1 || bank_run_app === true) {
        bank_run_app = 0
      } else {
        bank_run_app = 1
      }
      await axios
        .post('bank/bank_all/run_app', {
          bankid:bank_id,
          bankrun:bank_run_app,
          bank:'scb'
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_run_app === 1 ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_run_app === 1 ? 'check_box' : 'error',
          title: bank_run_app === 1 ? 'เปิดแสดง scb หน้าเว็บสำเร็จ' : 'ปิดแสดง scb หน้าเว็บสำเร็จ',
          text: bank_run_app === 1 ? `บัญชี : ${bank_fullname}` : `บัญชี : ${bank_fullname}`
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: bank_run_app === 1 ? 'เปิดดึงยอด scb หน้าเว็บไม่สำเร็จ!!' : 'ปิดดึงยอด scb หน้าเว็บไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async update_bankadd_app (bank_id, bank_fullname, bank_run_addcredit) {
      if (bank_run_addcredit === 1 || bank_run_addcredit === true) {
        bank_run_addcredit = 0
      } else {
        bank_run_addcredit = 1
      }
      await axios
        .post('bank/bank_all/run_add', {
          bankid:bank_id,
          bankrun:bank_run_addcredit,
          bank:'scb'
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: bank_run_addcredit === 1 ? 'success' : 'warning',
          position: 'top-right',
          icon: bank_run_addcredit === 1 ? 'check_box' : 'error',
          title: bank_run_addcredit === 1 ? 'เปิดแสดง scb หน้าเว็บสำเร็จ' : 'ปิดแสดง scb หน้าเว็บสำเร็จ',
          text: bank_run_addcredit === 1 ? `บัญชี : ${bank_fullname}` : `บัญชี : ${bank_fullname}`
        })

      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: bank_run_addcredit === 1 ? 'เปิดดึงยอด scb หน้าเว็บไม่สำเร็จ!!' : 'ปิดดึงยอด scb หน้าเว็บไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    },
    async popupDeleteBankData (id, number, name) {
      this.delete_id = id
      this.delete_bankno = number
      this.delete_fullname = name
      this.showPopupDelete = true
      this.$nextTick(() => {
        this.$refs.inputPassword.$el.querySelector('input').focus()
      })
    },
    async deletedata () {
      await axios
        .post('settingwallet/deleteBankTemporary', {
          bank_id: this.delete_id,
          token: this.otp
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })

        setTimeout(() => { window.location.reload() }, 1500)
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ลบข้อมูลไม่สำเร็จ',
          text: this.status.info
        })
        this.showPopupDelete = false
        this.otp = null
      }
    }


  }
}

</script>
