<template>
  <vx-card no-shadow title="เพิ่มบัญชีพัก">
    <!-- Info -->
    <vs-input
      class="w-full "
      v-validate="'required|numeric|max:20'"
      label-placeholder="หมายเลขบัญชี"
      v-model="bankno"
      name="bankno"
    ></vs-input>
    <span class="text-danger text-sm" v-show="errors.has('bankno')">{{
      errors.first("bankno")
    }}</span>
    <br />
    <vs-input
      class="w-full"
      v-validate="'required'"
      label-placeholder="ชื่อบัญชี (ชื่อ-นามสกุล)"
      v-model="fullname"
      name="fullname"
    ></vs-input>
    <span class="text-danger text-sm" v-show="errors.has('fullname')">{{
      errors.first("fullname")
    }}</span>
    <br />
    <vs-input
      class="w-full"
      v-validate="'required|alpha_num|max:20'"
      label-placeholder="ยูสเซอร์เนม"
      v-model="username"
      name="username"
    />
    <span class="text-danger text-sm" v-show="errors.has('username')">{{
      errors.first("username")
    }}</span>
    <br />
    <vs-input
      class="w-full"
      v-validate="'required'"
      label-placeholder="พาสเวิร์ด"
      v-model="password"
      name="password"
    ></vs-input>
    <span class="text-danger text-sm" v-show="errors.has('password')">{{
      errors.first("password")
    }}</span>
    <br />
    <vs-input
      class="w-full "
      v-validate="'required|numeric'"
      label-placeholder="เบอร์โทร"
      v-model="phone"
      name="phone"
    ></vs-input>
    <span class="text-danger text-sm" v-show="errors.has('phone')">{{
      errors.first("phone")
    }}</span>
    <br />
    <label class="vs-input--label">เลือกธนาคารที่ต้องการเพิ่มบัญชี</label>
    <v-select
      class="mt-1"
      v-model="bank"
      :options="bankOptions"
      name="status"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
    />
    <br />
    <label class="vs-input--label">เลือกประเภทบัญชีพัก</label>
    <v-select
      class="mt-1"
      v-model="bank_usage_type"
      :options="bank_usage_type_options"
      name="bank_usage_type"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
    />
    <br />
      <vs-input v-if="bank.value === '004'"
      class="w-full mt-5"
      label-placeholder="K-bank code"
      v-model="code"
      name="code"
    ></vs-input>
     <vs-input v-if="bank.value === '000'"
      class="w-full mt-5"
      label-placeholder="deviceId"
      v-model="deviceId"
      name="deviceId"
    ></vs-input>
      <vs-input v-if="bank.value === '000' || bank.value === '004'"
      class="w-full mt-5"
      label-placeholder="Pin"
      v-model="pin"
      name="pin"
    ></vs-input>
    <vs-input
      class="w-full"
      v-validate="'required'"
      label-placeholder="รายละเอียด"
      v-model="descriptions"
      name="descriptions"
    ></vs-input>
    <span class="text-danger text-sm mt-5 ">หากไม่ได้ใช้โอนต่อช่อง deviceId , kbank-code , pin ไม่ต้องกรอก</span>

    <br />
    <br />
    <br />
    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end mt-20">
      <vs-button
        :disabled="
          $store.state.AppActiveUser.permissions.setting.action ? false : true
        "
        class="ml-auto mt-2"
        color="success"
        @click="Save"
        >บันทึก</vs-button
      >
      <vs-button
        :disabled="
          $store.state.AppActiveUser.permissions.setting.action ? false : true
        "
        class="ml-4 mt-2"
        type="border"
        color="warning"
        @click="Reset()"
        >Reset</vs-button
      >
    </div>
  </vx-card>
</template>

<script>
import { Validator } from 'vee-validate'
import axios from '../../axios'

import vSelect from 'vue-select'
const dict = {
  custom: {
    username: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น',
      max: 'กรุณากรอกตัวอักษรไม่เกิน20ตัว'
    },
    password: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ต้องเป็นตัวเลขหรือจุดทศนิยมเท่านั้น'
    },
    fullname: {
      required: 'กรุณากรอกข้อมูล'
    },
    bankno: {
      required: 'กรุณากรอกข้อมูล',
      numeric: 'กรุณากรอกแต่ตัวเลขเท่านั้น',
      max: 'กรุณากรอกตัวอักษรไม่เกิน 20 ตัว'
    },
    phone: {
      required: 'กรุณากรอกข้อมูล',
      numeric: 'กรุณากรอกแต่ตัวเลขเท่านั้น'
    }
  }
}
Validator.localize('en', dict)
export default {
  components: {
    vSelect
  },
  data () {
    return {
      status: false,
      bankno: '',
      fullname: '',
      username: '',
      password: '',
      phone: '',
      bank: '',
      reserve: '',
      descriptions:'',
      deviceId : '',
      code : '',
      bankOptions: [
        {
          label: 'ไทยพาณิชย์ (SCB)',
          value: '000'
        },
        {
          label: 'กสิกรไทย (KBANK)',
          value: '004'
        },
        {
          label: 'กรุงไทย (KTB)',
          value: '006'
        },
        {
          label: 'กรุงศรีอยุธยา (BAY)',
          value: '025'
        },
        {
          label: 'เกียรตินาคิน (KKP)',
          value: '069'
        },
        {
          label: 'ออมสิน (GSB)',
          value: '030'
        },
        {
          label: 'ทหารไทยธนชาต',
          value: '011'
        },
        {
          label: 'กรุงเทพ',
          value: '002'
        },
        {
          label: 'ไอซีบีซี',
          value: '070'
        },
        {
          label: 'ไทยเครดิต',
          value: '071'
        },
        {
          label: 'ซิตี้แบงก์',
          value: '017'
        },
        {
          label: 'ซูมิโตโม มิตซุย',
          value: '018'
        },
        {
          label: 'สแตนดาร์ดชาร์เต',
          value: '020'
        },
        {
          label: 'ซีไอเอ็มบีไทย',
          value: '022'
        },
        {
          label: 'ยูโอบี',
          value: '024'
        },
        {
          label: 'เอชเอสบีซี',
          value: '031'
        },
        {
          label: 'มิซูโฮ',
          value: '039'
        },
        {
          label: 'ธกส.',
          value: '034'
        },
        {
          label: 'ธอส.',
          value: '033'
        },
        {
          label: 'แลนด์ แอนด์เฮ้าส์',
          value: '073'
        },
        {
          label: 'ทิสโก้',
          value: '067'
        },
        {
          label: 'อิสลาม',
          value: '066'
        }
      ],
      bank_usage_type: {
        label: 'บัญชีพัก (TEMP)',
        value: 'TEMP'
      },
      bank_usage_type_options: [
        {
          label: 'บัญชีพัก (TEMP)',
          value: 'TEMP'
        },
        {
          label: 'บัญชีกดเงิน (ATM)',
          value: 'ATM'
        },
        {
          label: 'บัญชีเบิก (BILL)',
          value: 'BILL'
        },
        {
          label: 'บัญชี (Payment)',
          value: 'PAY'
        },
        {
          label: 'บัญชีอื่นๆ (Other)',
          value: 'OTH'
        }
      ]
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    async Save () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          await axios
            .post('settingwallet/insertbanktemporary', {
              bankno: this.bankno,
              fullname: this.fullname,
              username: this.username,
              password: this.password,
              phone: this.phone,
              bankname: this.bank.label,
              bankcode: this.bank.value,
              reserve: this.reserve.value,
              bankbank: 'bank_temporary',
              bank_usage_type: this.bank_usage_type.value,
              descriptions: this.descriptions,
              code : this.code,
              deviceId : this.deviceId,
              pin : this.pin
            })
            .then(response => (this.status = response.data))
          if (this.status.status === true) {
            this.$vs.notify({
              time: 3000,
              color: 'success',
              position: 'top-right',
              icon: 'check_box',
              title: this.status.info
            })

            setTimeout(() => {
              window.location.reload()
            }, 1500)
          } else {
            this.$vs.notify({
              time: 8000,
              color: 'danger',
              position: 'top-right',
              icon: 'error',
              title: 'บันทึกไม่สำเร็จ',
              text: this.status.info
            })
          }
        } else {
          this.$vs.notify({
            time: 8000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'ไม่ได้กรอกข้อมูล',
            text: 'กรุณากรอกข้อมูลให้ครบถ้วน'
          })
        }
      })
    },
    Reset () {
      this.bankno = ''
      this.fullname = ''
      this.username = ''
      this.password = ''
      this.phone = ''
      this.bankbank = ''
      this.reserve = ''
      //  setTimeout(() => {  window.location.reload() }, 1000)
    }
  }
}
</script>
